<template>
  <trac-loading v-if="loading" />
  <main v-else>
    <trac-back-button class="pb-4">
      <span class="text-gray-600"> Back </span>
    </trac-back-button>

     <div v-if="showWrongPinWarning"
      class="fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center bg-black bg-opacity-75">
        <trac-incorrect-pin 
          @cancel="showWrongPinWarning = false" 
          :message="wrongPinWarning.message" 
          :blocked="wrongPinWarning.blocked" 
          @forgot-password="$router.push({name: 'ConfirmPassword', query:{ops: 'forgot_wallet_pin'}})"
        />
    </div>

    <div v-if="showUnsanctionedDeviceModal"
      class="fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center bg-black bg-opacity-75">
      <div class="rounded-md bg-white gap-y-5 p-12 w-4/12 flex flex-col content-center text-center">
        <div class="text-center"><img class="w-12 h-12 inline-block" src="@/assets/banned.svg" alt=""></div>
        <h3 class="font-bold">Unauthorized</h3>
        <p class="text-xs">You are not authorized to perform the selected action</p>
        <div></div>
        <trac-button class="uppercase" @button-clicked="$router.push({name: 'Dashboard'})">Back to Home</trac-button>
      </div>
    </div>

    <trac-modal v-if="showPasswordModal">
      <h3 class="my-5 font-bold text-center text-primaryBlue">Session Expired</h3>
      <p class="text-xs text-center">
        Please enter your Traction password to resume your progress
      </p>
      <trac-input
        v-model.trim="payload.password"
        type="password"
        placeholder="Password"
        specified="password"
        class="mt-6 w-full md:w-128"
        tag="div"
      />
      <div class="text-center mt-5">
        <trac-button @button-clicked="reVerify()">Proceed</trac-button>
      </div>
    </trac-modal>

    <h1 class="text-gray-800 font-semibold capitalize text-sm">
      {{ $route.params.type }}
    </h1>
    <div
      class="shadow pt-10 pb-40 border w-full rounded-lg mt-8 flex flex-col items-center justify-center"
    >
      <div class="w-6/12 flex flex-col items-center justify-center">
        <img class="w-16 h-16" :src="iconSwitch.icon" alt="icon" />
        <p class="text-base font-semibold mt-3 text-primaryBlue leading-7">
          Add {{ iconSwitch.text }} Details
        </p>
        <p class="text-xs text-center mt-2">
          Please fill the details below to pay for your {{ $route.params.type }}
        </p>

        <div class="mt-6 px-8 py-4 border-dashed border bg-gray-100 rounded-lg">
          <p class="text-xs text-center text-primaryBlue">
            <span v-if="iconSwitch.text === 'Cable Tv'"> Decoder </span>
            <span v-else-if="iconSwitch.text === 'Electricity'"> Disco </span>
            <span v-else-if="iconSwitch.text === 'Internet'">
              Internet Provider
            </span>
            <span v-else-if="iconSwitch.text === 'Airtime'"> Airtime </span>

            Information
          </p>
          <div class="flex items-center gap-5 mt-2">
            <img
              v-if="$route.params.package === 'dstv'"
              class="w-16 h-16"
              src="../../assets/svg/dstv.svg"
              alt=""
            />
            <div v-else>
              <img class="w-16 h-16" :src="iconSwitch.icon" alt="" />
            </div>
            <div class="font-medium">
              <p class="text-sm">{{ $route.params.name }}</p>
              <p class="text-sm">{{ $route.params.smartCardNumber }}</p>
            </div>
          </div>
        </div>
        <div class="w-full">
          <trac-custom-dropdown
            v-if="
              iconSwitch.text !== 'Airtime' && iconSwitch.text !== 'Electricity'
            "
            @input="isSearching"
            :value="value"
            class="mt-5"
            labelName="Package"
            :label="true"
            placeholder="Select Package"
          >
            <template>
              <div v-for="(items, index) in filterPackages" :key="index">
                <div
                  @click="update(items)"
                  class="py-3 px-2 hover:bg-gray-100 group hover:text-primaryBlue cursor-pointer"
                >
                  <p class="text-sm font-medium">
                    {{ refactorName(items.name) }}
                  </p>
                  <p v-if="iconSwitch.text === 'Cable Tv'" class="text-xs">
                    {{ refactorPrice(items.name) }} per month
                  </p>
                </div>
              </div>
            </template>
          </trac-custom-dropdown>

          <trac-input :disabled="isCable || isInternet" placeholder="Amount" class="mt-10" :min=1 :max=10000000 type="number" v-model="amount">
          </trac-input>
          <div
            :class="[
              {
                'text-red-500': amount > walletAmount || walletAmount === 0,
              },
              {
                'text-green-600': amount <= walletAmount && amount !== '',
              },
            ]"
            class="text-xs text-right mt-2"
          >
            Available Wallet Balance {{ walletAmount | formatPrice }}
          </div>
          <div class="text-xs text-right text-red-600">
            <span v-if="!amountComputedWithinLimits.valid">{{ amountComputedWithinLimits.reason }}</span>
          </div>
          <trac-button
            :disabled="!amountIsValid"
            @click.native="verify"
            class="w-full mt-5"
          >
            <span class="uppercase"> Continue </span>
          </trac-button>
        </div>
      </div>
    </div>
    <trac-modal
      v-if="confirmationModal"
      @close="confirmationModal = false"
      size=" max-w-2xl"
    >
      <h1 class="text-sm font-medium">Confirm Purchase</h1>
      <p class="text-center text-2xl py-5 font-semibold">
        {{ amount | formatPrice }}
      </p>
      <div class="max-w-md mx-auto bg-gray-100 p-5 space-y-8 mt-5">
        <div class="grid grid-cols-2 items-center">
          <div v-if="iconSwitch.text === 'Cable Tv'" class="text-xs">
            Smartcard Number
          </div>
          <div v-else-if="iconSwitch.text === 'Electricity'" class="text-xs">
            Meter Number
          </div>
          <div v-else-if="iconSwitch.text === 'Internet'" class="text-xs">
            Customer Id
          </div>
          <div v-else-if="iconSwitch.text === 'Airtime'" class="text-xs">
            Phone Number
          </div>

          <div class="text-sm font-medium">
            {{ $route.params.smartCardNumber }}
          </div>
        </div>
        <div v-if="!(iconSwitch.text === 'Internet')" class="grid grid-cols-2 items-center">
          <div class="text-xs">Customer Name</div>
          <div class="text-sm font-medium">
            {{ $route.params.name }}
          </div>
        </div>

        <div
          v-if="
            iconSwitch.text !== 'Airtime' && iconSwitch.text !== 'Electricity'
          "
          class="grid grid-cols-2 items-center"
        >
          <div class="text-xs">Package</div>
          <div class="text-sm font-medium">
            {{ value }}
          </div>
        </div>
        <div
          v-if="iconSwitch.text === 'Electricity'"
          class="grid grid-cols-2 items-center"
        >
          <div class="text-xs">Package</div>
          <div class="text-sm font-medium capitalize">
            {{ $route.params.code }}
          </div>
        </div>
        <div class="grid grid-cols-2 items-center">
          <div class="text-xs">Cashback</div>
          <div
            v-if="iconSwitch.text === 'Cable Tv'"
            class="text-sm font-medium"
          >
            -
          </div>
          <div
            v-else-if="iconSwitch.text === 'Electricity'"
            class="text-sm font-medium"
          >
            0
          </div>
          <div
            v-else-if="iconSwitch.text === 'Internet'"
            class="text-sm font-medium"
          >
            -
          </div>
        </div>
      </div>
      <div class="max-w-md mx-auto my-8">
        <trac-button
          @click.native="
            openPinModal = true;
            confirmationModal = false;
          "
          class="w-full mx-auto text-center uppercase"
        >
          Proceed
        </trac-button>

        <p
          @click="confirmationModal = false"
          class="text-center uppercase text-xs mt-4 font-medium text-red-600 cursor-pointer"
        >
          cancel
        </p>
      </div>
    </trac-modal>

    <trac-modal
      @close="openPinModal = false"
      size="max-w-2xl"
      v-if="openPinModal"
    >
      <div class="mt-8 pb-10 px-8">
        <div class="flex flex-col justify-center items-center">
          <h1>Enter your Wallet PIN</h1>
          <div class="text-xs font-light mt-3">
            Please enter your Wallet pin to complete this transaction
          </div>
          <div class="flex mt-8 w-64 justify-between">
            <input
              autofocus
              class="border border-gray-500 w-10 h-10 text-center"
              type="text"
              maxlength="1"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              v-on:keyup="
                check(numberOne)
                  ? $event.target.nextElementSibling.focus()
                  : false
              "
              v-model.number="numberOne"
            />
            <input
              class="border border-gray-500 w-10 h-10 text-center"
              type="text"
              maxlength="1"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              v-on:keyup="
                check(numberTwo)
                  ? $event.target.nextElementSibling.focus()
                  : false
              "
              v-model.number="numberTwo"
            />
            <input
              class="border border-gray-500 w-10 h-10 text-center"
              type="text"
              maxlength="1"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              v-on:keyup="
                check(numberThree)
                  ? $event.target.nextElementSibling.focus()
                  : false
              "
              v-model.number="numberThree"
            />
            <input
              class="border border-gray-500 w-10 h-10 text-center"
              type="text"
              maxlength="1"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              v-on:keyup="
                check(numberFour)
                  ? $event.target.nextElementSibling.focus()
                  : false
              "
              v-model.number="numberFour"
            />
          </div>
          <div class="mt-12">
            <trac-button @click.native="verifyPin">
              <div class="px-10">Submit</div>
            </trac-button>
          </div>
        </div>
      </div>
    </trac-modal>

    <trac-modal
      @close="incorrectModal = false"
      size="max-w-2xl"
      v-if="incorrectModal"
    >
      <div class="my-5 flex flex-col space-y-10 justify-center items-center">
        <svg
          width="88"
          height="88"
          viewBox="0 0 88 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="44" cy="44" r="42" stroke="#CE1112" stroke-width="4" />
          <path
            d="M64.0005 23.9999L24.0005 63.9999"
            stroke="#CE1112"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M24.0005 23.9999L64.0005 63.9999"
            stroke="#CE1112"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <p class="text-sm">Incorrect PIN. Please try again</p>

        <trac-button
          @click.native="
            incorrectModal = false;
            openPinModal = true;
          "
        >
          <div class="px-10">Back</div>
        </trac-button>
      </div>
    </trac-modal>

    <trac-modal
      @close="successModal = false"
      size="max-w-4xl"
      v-if="successModal"
    >
      <div class="my-5 flex flex-col space-y-5 justify-center items-center">
        <img src="../../assets/svg/handshake.svg" alt="" />
        <p class="text-lg font-medium">Payment Processed</p>

        <p v-if="iconSwitch.text === 'Cable Tv'" class="text-sm">
          Your Cable TV payment has been processed
        </p>
        <p v-else-if="iconSwitch.text === 'Electricity'" class="text-sm">
          Your Electricity Bill payment has been processed
        </p>
        <p v-else-if="iconSwitch.text === 'Internet'" class="text-sm">
          Your Internet Service payment has been processed
        </p>

        <div
          class="mt-4 max-w-xl text-sm w-full border-dashed border-blue-400 border-2 bg-blue-100"
        >
          <p class="text-center my-4">Your Transaction Details</p>

          <div v-if="iconSwitch.text === 'Cable Tv'" class="grid grid-cols-2 mt-2 px-20 gap-4 items-center">
            <div class="text-xs">Cable Tv Provider</div>
            <div class="text-sm font-medium uppercase">
              {{ successMessage.serviceID }}
            </div>
          </div>

          <div class="grid grid-cols-2 gap-4 px-20 items-center mt-2">
            <div v-if="iconSwitch.text === 'Cable Tv'" class="text-xs">
              Smartcard Number
            </div>
            <div v-else-if="iconSwitch.text === 'Electricity'" class="text-xs">
              Meter Number
            </div>
            <div v-else-if="iconSwitch.text === 'Internet'" class="text-xs">
              Customer ID
            </div>
            <div v-else-if="iconSwitch.text === 'Airtime'" class="text-xs">
              Phone Number
            </div>

            <div class="text-sm font-medium">
              {{ $route.params.smartCardNumber }}
            </div>
          </div>

          <div class="grid grid-cols-2 mt-2 px-20 gap-4 items-center">
            <div class="text-xs">Amount paid</div>
            <div class="text-sm font-medium">
              {{ successMessage.amount | formatPrice }}
            </div>
          </div>

          <div
            v-if="(iconSwitch.text === 'Internet')"
            class="grid grid-cols-2 mt-2 gap-4 items-center px-20"
          >
            <div class="text-xs">Internet service Provider:</div>
            <div class="text-sm font-medium">
              {{ $route.params.package }}
            </div>
          </div>

          <div v-if="!(iconSwitch.text === 'Internet')" class="grid grid-cols-2 gap-4 px-20 mt-2 items-center">
            <div class="text-xs">Customer Name</div>
            <div class="text-sm font-medium">
              {{ $route.params.name }}
            </div>
          </div>
          
          <div
            v-if="iconSwitch.text !== 'Airtime'"
            class="grid grid-cols-2 gap-4 px-20 mt-2 items-center"
          >
            <div class="text-xs">Package</div>
            <div class="text-sm font-medium capitalize">
              {{ value || $route.params.code }}
            </div>
          </div>
          <div
            v-if="iconSwitch.text === 'Electricity'"
            class="grid grid-cols-2 gap-4 px-20 mt-2 items-center"
          >
            <div class="text-xs">Electricity Disco</div>
            <div class="text-sm font-medium capitalize">
              {{ $route.params.package }}
            </div>
          </div>
          <div class="grid grid-cols-2 gap-4 px-20 my-2 items-center">
            <div class="text-xs">Cashback</div>
            <div
              v-if="
                iconSwitch.text === 'Cable Tv' || iconSwitch.text === 'Internet'
              "
              class="text-sm font-medium"
            >
              -
            </div>
            <div
              v-else-if="iconSwitch.text === 'Electricity'"
              class="text-sm font-medium"
            >
              0
            </div>
          </div>
        </div>

        <div class="text-center text-sm">
          Please check your wallet transaction receipt for your payment status.
          <br />
          If your payment is successful, you will get an email with your payment
          receipt.
        </div>

        <div class="flex items-center justify-center text-gray-600 mt-5">
          <!-- successModal -->
          <p>{{ moment().format("DD MMMM yyyy") }}</p>
          <p class="px-3">|</p>
          <p>{{ moment().format("HH:mm A") }}</p>
        </div>
        <div class="text-center mt-3 text-gray-600">
          <p>Transaction Id - {{ successMessage.request_id || 'N/A' }}</p>
        </div>

        <trac-button
          @click.native="
            $router.push({
              name: 'MainWallet',
            })
          "
        >
          <div class="px-10">Back to Wallet</div>
        </trac-button>
      </div>
    </trac-modal>
    <trac-modal @close="errorModal = false" size="max-w-2xl" v-if="errorModal">
      <div class="my-5 flex flex-col space-y-10 justify-center items-center">
        <svg
          width="134"
          height="133"
          viewBox="0 0 134 133"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M25.2726 113.625C16.7384 113.625 11.4184 104.37 15.6855 96.9999L57.4142 24.9028C61.6813 17.5324 72.3213 17.5324 76.5884 24.9028L118.317 96.9999C122.584 104.37 117.264 113.625 108.73 113.625H25.2726ZM108.731 102.542L67.0018 30.4445L25.2731 102.542H108.731ZM61.459 58.2083V69.2916C61.459 72.3395 63.9527 74.8333 67.0007 74.8333C70.0486 74.8333 72.5423 72.3395 72.5423 69.2916V58.2083C72.5423 55.1604 70.0486 52.6666 67.0007 52.6666C63.9527 52.6666 61.459 55.1604 61.459 58.2083ZM72.5423 96.9999V85.9166H61.459V96.9999H72.5423Z"
            fill="#FF3636"
          />
        </svg>

        <p class="text-sm">Your bill payment failed</p>

        <trac-button
          @click.native="
            openPinModal = true;
            errorModal = false;
          "
        >
          <div class="px-10">Retry</div>
        </trac-button>
      </div>
    </trac-modal>
  </main>
</template>

<script>
import cableTv from "@/assets/svg/cable-tv.svg";
import internet from "@/assets/svg/internet.svg";
import utilities from "@/assets/svg/utilities.svg";
import cableImages from "@/assets/svg/cable-images.svg";
import internetImages from "@/assets/svg/internet-images.svg";
import utilitiesImages from "@/assets/svg/utilities-images.svg";
import PaymentLimitsMixin from '../../../src/views/wallet/PaymentLimits'
import { GET_USER_BUSINESS_DATA } from "../../browser-db-config/localStorage";
import moment from "moment";
import airtime from "@/assets/svg/buy_airtime.svg";
import WalletToken from '../security/WalletToken';
import WhiteListingMixin from '../../security-module/WhiteListingMixin';

export default {
  mixins: [PaymentLimitsMixin, WalletToken, WhiteListingMixin],
  data() {
    return {
      selectedPackaged: 'N/A',
      moment,
      options: [],
      confirmPayment: false,
      cableTv: {
        smartCardNumber: "",
        package: "",
      },
      packages: [],
      searching: "",
      value: "",
      variation_code: "",
      trigger: false,
      amount: "",
      walletAmount: 0,
      loading: false,
      confirmationModal: false,
      openPinModal: false,
      numberOne: null,
      numberTwo: null,
      numberThree: null,
      numberFour: null,
      incorrectModal: false,
      successModal: false,
      errorModal: false,
      successMessage: null,
      request_id: null,
    };
  },
  computed: {
    allowedToProeed() {
      return this.amountComputed < this.walletAmount || this.walletAmount === 0 || this.amountComputed === ''
    },
    amountComputedWithinLimits() {
      const ElectricityPayment = this.iconSwitch.text === 'Electricity'
      const CablePayment = this.iconSwitch.text === 'Cable Tv'
      if (CablePayment) {
        return { valid: true, reason: ''}
      } else if(ElectricityPayment) {
        return this.checkElectricityLimits(this.amountComputed)
      } else {
        return { valid: false, reason: ''}
      }
    },
    amountComputed() {
      return parseFloat(this.amount)
    },
    amountIsValid() {
      return (this.amountComputed > 0 && this.amountComputed < this.walletAmount) && this.amountComputedWithinLimits.valid
    },
    iconSwitch() {
      switch (this.$route.params.type) {
        case "Cable Tv":
          return {
            icon: cableTv,
            text: "Cable Tv",
            image: cableImages,
          };
        case "Internet":
          return {
            icon: internet,
            text: "Internet",
            image: internetImages,
          };
        case "Electricity":
          return {
            icon: utilities,
            text: "Electricity",
            image: utilitiesImages,
          };
        case "Airtime":
          return {
            icon: airtime,
            text: "Airtime",
            image: airtime,
          };
      }
    },
    isElectricity () {
      return this.iconSwitch.text === 'Electricity'
    },
    isInternet () {
      return this.iconSwitch.text === 'Internet'
    },
    isCable () {
      return this.iconSwitch.text === 'Cable Tv'
    },
    filterPackages() {
      return this.packages.filter((item) => {
        return item.name.toLowerCase().includes(this.searching.toLowerCase());
      });
    },
  },
  methods: {
    reVerify() {
      this.verifyThenProcess().then(() => {
        this.showPasswordModal = false
        this.verifyPin()
      })
    },
    update(item) {
      this.value = item.name;
      this.variation_code = item.variation_code;
      this.searching = "";
      this.amount = item.variation_amount;
    },
    verify() {
      this.confirmationModal = true;
    },
    async verifyPin() {
      this.loading = true
      const pin = `${this.numberOne}${this.numberTwo}${this.numberThree}${this.numberFour}`
      const response = await this.$store.dispatch("VERIFY_USER_PIN", {
        pin,
      });
      if (!response.status) {
        this.checkIncorrectPin(response)
        this.isSanctioned(response)
        this.openPinModal = false;
        // this.incorrectModal = true;
        this.numberOne = null;
        this.numberTwo = null;
        this.numberThree = null;
        this.numberFour = null;
        this.loading = false
      } else {
        const [tokenRes, error] = await this.verifyWalletToken()
        if (tokenRes.status) {
            const response = await this.$store.dispatch("PAY_BILL", {
            serviceID: this.$route.params.package,
            amount: +this.amount,
            billerCode: this.$route.params.smartCardNumber,
            variation_code: this.variation_code,
            quantity: 1,
            phone: GET_USER_BUSINESS_DATA().phone || "",
            customerName: this.$route.params.name,
          });

          if (response.status) {
            this.openPinModal = false;
            this.successModal = true;
            this.successMessage = response.data;
            this.loading = false
          } else {
            this.openPinModal = false;
            this.errorModal = true;
            this.loading = false
          }

          this.numberOne = null;
          this.numberTwo = null;
          this.numberThree = null;
          this.numberFour = null;
        } else {
          this.showPasswordModal = true
          this.openPinModal = false
          this.temporaryPin = pin
          this.loading = false
        }
      }
    },
    check(x) {
      if (typeof x == "number") {
        return true;
      }
    },
    selectOption(value) {
      this.cableTv.package = value.ServiceID;
    },
    isSearching(value) {
      this.searching = value;
    },
    refactorName(name) {
      if (this.iconSwitch.text === "Cable Tv") {
        const index = name.lastIndexOf("N");
        return name.slice(0, index - 1);
      } else {
        return name;
      }
    },

    refactorPrice(price) {
      if (this.iconSwitch.text === "Cable Tv") {
        const index = price.lastIndexOf("N");
        return price.slice(index);
      } else {
        return price;
      }
    },
  },
  async mounted() {
    this.loading = true;
    if (this.iconSwitch.text === "Electricity") {
      this.variation_code = this.$route.params.code;
    }
    const walletDetails = await this.$store.dispatch("FETCH_WALLET_DETAILS");

    const response = await this.$store.dispatch(
      "FETCH_PACKAGE",
      this.$route.params.package
    );
    if (response && response.status) {
      this.packages = response.data.content.variations ?? [];
      this.selectedPackaged = response.data.content.ServiceName
    }

    this.walletAmount = walletDetails.data.amount || 0;

    this.loading = false;
  },
};
</script>

<style></style>
